import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import Style from "./kv.module.scss"
import Typist from "react-typist"

function Kv() {
  return (
    <div className={Style.wrap}>
      <div className={Style.overlay}></div>
      <Container className={Style.content}>
        <Row>
          <Col sm={12} className="mx-auto">
            <div className={Style.siteHeading}>
              <Typist
                className={Style.topText}
                avgTypingDelay={95}
                cursor={{ hideWhenDone: true }}
              >
                <span>Hello World.</span>
                <Typist.Delay ms={1000} />
                <br />
                <span>FIRE を目指すエンジニアのブログ</span>
                <Typist.Delay ms={800} />
                <br />
                <span>高配当株式投資積立中！ゴホゴホ</span>
                <Typist.Backspace count={15} delay={200} avgTypingDelay={20} />
                <span>WEB技術などを記事にしていきます！</span>
                <Typist.Delay ms={500} />
                <Typist.Backspace count={18} delay={500} />
                <span>Happy Coding!</span>
              </Typist>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  )
}

export default Kv
